<template>
	<div class="text-sm w-full flex flex-col justify-between items-center chatbot">
		<div class="chatbot-container w-full">
			<div class="w-full quickBox" v-if="messages.length === 0">
				<div class="w-full flex justify-center mb-10">
					<img :src="require('@/assets/img/botCenter.svg')" alt="user-icon" class="h-10 mr-2" />
				</div>
				<div class="w-full flex justify-center mb-10 headText">Your Make AI Assistant</div>
				<div class="quick-questions-container flex space-x-4">
					<div v-for="(question, index) in quickQuestion" :key="index" class="question-box"
						@click="handleQuickQuestionClick(question)">
						{{ question }}
					</div>
				</div>
			</div>
			<div class="scrollable-container" ref="chatMessages">
				<div class="chat-messages">
					<span class="w-full flex" v-for="(message, index) in messages" :key="index"
						:class="{ 'justify-end': message.isUser, 'justify-start': !message.isUser }">
						<div class="flex flex-col items-start"
							:class="{ 'items-end': message.isUser, 'items-start': !message.isUser }">
							<div class="flex items-center"
								:class="{ 'justify-end': message.isUser, 'justify-start': !message.isUser }">
								<span class="text-xs text-white mr-5 ml-5">{{ formatData(message.timeStamp) }}</span>
								<img v-if="message.isUser" :src="require('@/assets/img/user-image.svg')" alt="user-icon"
									class="h-10 mr-2" />
								<img v-else :src="require('@/assets/img/bot.svg')" alt="bot-icon" class="h-4 mr-2" />
							</div>
							<div class="message mt-1"
								:class="{ 'user-message': message.isUser, 'bot-message': !message.isUser }"
								v-html="message.text"></div>
						</div>
					</span>

					<div class="w-full flex justify-around">
						<img v-if="isLoading" src="@/assets/gif/loading-gif.gif" alt="Loading..." class="loading-gif" />
					</div>
				</div>
			</div>

			<div class="user-input flex space-x-3">

				<input class="w-3/4 h-10 pr-12" v-model="userMessage" @keyup.enter="sendMessage"
					placeholder="Enter your query here" :disabled="isLoading" />
				<button :disabled="isLoading" @click="sendMessage" class="button-purple w-1/4 rounded-md font-bold">
					Ask
				</button>
				<input type="file" ref="fileInput" @change="handleFileChange" class="file-upload-input w-1/4" />

				<button :disabled="!selectedFile" @click="uploadFile" class="button-purple w-1/4 rounded-md font-bold">
					Upload File
				</button>
			</div>

		</div>
	</div>
</template>

<script>
	import axios from "axios";

	export default {
		props: {
			quickQuestion: Array,
		},
		data() {
			return {
				userMessage: "",
				messages: [
					// {
					// 	text: "Hello! How can I help you today?",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:00:00"),
					// },
					// {
					// 	text: "Hi! I wanted to know more about your services.",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:01:00"),
					// },
					// {
					// 	text: "Sure! We offer a range of AI solutions to streamline your business. Are you looking for something specific?",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:02:00"),
					// },
					// {
					// 	text: "Yes, do you have any options for customer support automation?",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:03:00"),
					// },
					// {
					// 	text: "Absolutely! Our AI-powered chatbot can assist with 24/7 customer support, answering queries, and routing complex issues to human agents.",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:04:00"),
					// },
					// {
					// 	text: "That sounds interesting. How can I integrate it into my website?",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:05:00"),
					// },
					// {
					// 	text: "Integration is simple! Our team can assist you with embedding the chatbot on your website or app with minimal setup.",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:06:00"),
					// },
					// {
					// 	text: "Hello! How can I help you today?",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:00:00"),
					// },
					// {
					// 	text: "Hi! I wanted to know more about your services.",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:01:00"),
					// },
					// {
					// 	text: "Sure! We offer a range of AI solutions to streamline your business. Are you looking for something specific?",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:02:00"),
					// },
					// {
					// 	text: "Yes, do you have any options for customer support automation?",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:03:00"),
					// },
					// {
					// 	text: "Absolutely! Our AI-powered chatbot can assist with 24/7 customer support, answering queries, and routing complex issues to human agents.",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:04:00"),
					// },
					// {
					// 	text: "That sounds interesting. How can I integrate it into my website?",
					// 	isUser: true,
					// 	timeStamp: new Date("2023-11-10T10:05:00"),
					// },
					// {
					// 	text: "Integration is simple! Our team can assist you with embedding the chatbot on your website or app with minimal setup.",
					// 	isUser: false,
					// 	timeStamp: new Date("2023-11-10T10:06:00"),
					// },
				], isLoading: false,
				chatId: 1, // Store chat ID for continued session
				messageFetchInterval: null, // Interval for polling bot responses
				selectedFile: null, // New property to hold the selected file
			};
		},
		methods: {
			// Method to handle file selection
			handleFileChange(event) {
				this.selectedFile = event.target.files[0];
			},

			// Method to upload the file to the server
			async uploadFile() {
				if (!this.selectedFile) return;
				this.isLoading = true;

				const formData = new FormData();
				formData.append("file", this.selectedFile);

				try {

					const response = await axios.post(
						`${process.env.VUE_APP_API_BASE_URL}/file_upload/file`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								"Access-Control-Allow-Origin": "*"
							}
						}
					);

					if (response.status === 200) {
						this.selectedFile = null;
						this.isLoading = false;
						alert('File uploaded successfully');
						// this.messages.push({
						// 	text: `File uploaded successfully: ${response.data.message}`,
						// 	isUser: false,
						// 	timeStamp: new Date(),
						// });
					}
				} catch (error) {
					console.error("Failed to upload file:", error);

					// this.messages.push({
					// 	text: "Failed to upload file.",
					// 	isUser: false,
					// 	timeStamp: new Date(),
					// });
				} finally {
					this.isLoading = false;
					this.selectedFile = null; // Reset the file input after upload
				}
			},

			handleQuickQuestionClick(question) {
				this.userMessage = question;
				this.sendMessage();
			},
			formatData(originalDate) {
				const date = new Date(originalDate);
				const dateOptions = { day: "2-digit", month: "short" };
				const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
				const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
				const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
				return `${formattedDate} | ${formattedTime}`;
			},
			async sendMessage() {
				const userText = this.userMessage.trim();
				if (!userText) return;

				this.messages.push({
					text: userText,
					isUser: true,
					timeStamp: new Date(),
				});

				this.userMessage = "";
				this.isLoading = true;

				try {

					const response = await axios.post(
						`${process.env.VUE_APP_API_BASE_URL}/file_upload/send_message`, {
						message: userText,
						chat_id: this.chatId,
						web_search: true,
						multi_turn: false
					},
						{
							headers: {
								"Content-Type": "application/json",
								"Access-Control-Allow-Origin": "*"
							}
						}
					);

					console.log('response: ', response);
					if (response.status === 200 && response.data.formatted_message) {
						this.messages.push({
							text: response.data.formatted_message,
							isUser: false,
							timeStamp: new Date(),
						});
						this.isLoading = false;
					}
					// // Send user message to the backend
					// const response = await axios.post("/send_message", {
					// 	message: userText,
					// 	chat_id: this.chatId,
					// });

					// if (response.data.success) {
					// 	this.chatId = response.data.chat_id;
					// 	this.chatId = response.data.formatted_message;

					// 	// this.pollBotResponse();
					// }

				} catch (error) {
					console.error("Failed to send message:", error);
				}
			},
			pollBotResponse() {
				if (this.messageFetchInterval) clearInterval(this.messageFetchInterval);

				this.messageFetchInterval = setInterval(async () => {
					try {

						const response = await axios.get(
							`${process.env.VUE_APP_API_BASE_URL}/file_upload/get_message`,
							//   { query: userText },
							{
								headers: {
									"Content-Type": "application/json",
									"Access-Control-Allow-Origin": "*"
								}
							}
						);


						if (response.status === 200 && response.data.message) {
							this.messages.push({
								text: response.data.message,
								isUser: false,
								timeStamp: new Date(),
							});
							this.isLoading = false;
							clearInterval(this.messageFetchInterval);
						}
					} catch (error) {
						console.error("Failed to get message:", error);
					}
				}, 500); // Polling every 500 ms
			},
		},
	};
</script>
<style scoped>
	.scrollable-container {
		flex: 1;
		/* width: 100%; */
		overflow-y: auto;
	}

	.file-upload-input {
		/* display: none; */
	}

	.chatbot {
		max-height: 100%;
		/* Adjust as needed */
		overflow-y: auto;
		padding-top: 40px;
	}

	.text-sm {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100%;
	}

	.chatbot-container {
		width: 100%;
		/* max-width: 750px; */
		/* height: 88vh; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* Center all items vertically */
		align-items: center;
		/* Center all items horizontally */
		padding: 20px;
		border-radius: 10px;
		margin: 0 auto;
	}

	.quickBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.quick-questions-container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		gap: 16px;
		margin-bottom: 20px;
		width: 100%;
	}

	.question-box {
		flex: 1;
		/* max-width: 200px; */
		padding: 10px 15px;
		border-radius: 8px;
		border: 1px solid #55A8B5;
		background: #2F484E;
		color: white;
		text-align: center;
		white-space: normal;
		line-height: 1.4;
	}

	.headText {
		color: var(--Icons-Icons-Default-Icon, #FFF);
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: var(--font-line-height-lg, 32px);
		/* 160% */
	}

	.quick-questions-container2 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;
	}

	.question-box2 {
		flex: 1;
		/* Ensures equal spacing for each box */
		padding: 10px 15px;
		border-radius: 8px;
		border: 1px solid #55A8B5;
		background: #2F484E;
		backdrop-filter: blur(23px);
		color: white;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;

		/* Allow text to wrap naturally */
		white-space: normal;
		line-height: 1.4;
		/* Adjust line height for readability */
	}

	.text-sm3 {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		/* Full viewport height to center vertically */
		width: 100%;
	}

	.chatbot-container2 {
		width: 100%;
		/* max-width: 750px; */
		/* Adjusts the max width for centering */
		/* height: 88vh; */
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 20px;
		border-radius: 10px;
		margin: 0 auto;
		/* Centers horizontally */
		/* background-color: #2F484E; */
		/* Optional background color */
		/* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
		/* Optional shadow for better visibility */
	}

	.message {
		padding: 8px 20px;
		margin: 10px;
		word-wrap: break-word;
		/* width: fit-content; */
		/* max-width: 80%; */
		border-radius: 8px;
		display: block;
		background-color: #55A8B5;
	}

	.chat-messages {
		/* overflow-y: scroll; */
		width: 1200px;
		scrollbar-color: #4f4f4f #2e2e2e;
		scrollbar-width: thin;
	}

	.user-message {
		background-color: #55A8B5;
		/* Dark purple for user messages */
		color: #ffffff;
	}

	.bot-message {
		background-color: #333333;
		/* Lighter dark color for bot messages */
		color: #ffffff;
	}

	.chat-messages::-webkit-scrollbar {
		width: 6px;
	}

	.chat-messages::-webkit-scrollbar-thumb {
		background-color: #4f4f4f;
		border-radius: 10px;
	}

	.user-input {
		width: 100%;
		display: flex;
		margin-top: 10px;
		background-color: #2f484e;
		padding: 10px;
		box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
		position: sticky;
		bottom: 0;
	}

	input {
		color: #ffffff;
		padding: 8px;
		background: rgba(130, 130, 130, 0.3);
		/* Semi-transparent for black */
		border: 1px solid #4f4f4f;
		border-radius: 5px;
		outline: none;
	}

	input::placeholder {
		color: #bbbbbb;
	}

	.button-purple {
		background-color: #8e24aa;
		/* Purple button */
		color: white;
		cursor: pointer;
	}

	.button-purple:disabled {
		background-color: #4f4f4f;
		cursor: not-allowed;
	}

	.chatbot {
		/* Sets a max height for the chatbot container */
		max-height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow: hidden;
	}

	.chatbot-container {
		/* Restrict chatbot container within chatbot div */
		width: 100%;
		/* max-width: 750px; */
		max-height: 80vh; /* or 100vh - padding if you have a header */
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.scrollable-container {
		/* Keeps messages scrollable within the container */
		flex: 1;
		width: 100%;
		max-height: calc(80vh - 150px); /* adjust based on other elements */
		overflow-y: auto;
	}

	.chat-messages {
		/* Keeps messages within scrollable container */
		width: 100%;
		overflow-y: auto;
		padding-right: 10px;
		scrollbar-color: #4f4f4f #2e2e2e;
		scrollbar-width: thin;
	}

	/* Additional Styling for Chat Messages */
	.message {
		padding: 8px 15px;
		margin: 10px;
		border-radius: 8px;
		display: inline-block;
		background-color: #55A8B5;
		word-wrap: break-word;
		/* max-width: 80%; */
	}

	.user-message {
		background-color: #55A8B5;
		color: #ffffff;
		align-self: flex-end;
	}

	.bot-message {
		background-color: #333333;
		color: #ffffff;
		align-self: flex-start;
	}

	.user-input {
		width: 100%;
		display: flex;
		margin-top: 10px;
		background-color: #2f484e;
		padding: 10px;
		box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
		position: sticky;
		bottom: 0;
	}
</style>