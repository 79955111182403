<template>
    <v-app>
      <v-container>
        <v-menu
          offset-y
          activator="menu1"
          v-model="menu1"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Main Menu
            </v-btn>
          </template>
  
          <v-list>
            <v-list-item @click="selectItem('Item 1')">
              <v-list-item-title>Item 1</v-list-item-title>
            </v-list-item>
  
            <!-- First Nested Menu -->
            <v-menu
              offset-y
              close-on-content-click
              v-model="submenu1"
              activator="submenu1Activator"
            >
              <template #activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="submenu1 = !submenu1"
                >
                  <v-list-item-title>Submenu 1</v-list-item-title>
                </v-list-item>
              </template>
  
              <v-list>
                <v-list-item @click="selectItem('Submenu 1 - Item A')">
                  <v-list-item-title>Submenu 1 - Item A</v-list-item-title>
                </v-list-item>
                <v-list-item @click="selectItem('Submenu 1 - Item B')">
                  <v-list-item-title>Submenu 1 - Item B</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
  
            <!-- Second Nested Menu -->
            <v-menu
              offset-y
              close-on-content-click
              v-model="submenu2"
              activator="submenu2Activator"
            >
              <template #activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="submenu2 = !submenu2"
                >
                  <v-list-item-title>Submenu 2</v-list-item-title>
                </v-list-item>
              </template>
  
              <v-list>
                <v-list-item @click="selectItem('Submenu 2 - Item A')">
                  <v-list-item-title>Submenu 2 - Item A</v-list-item-title>
                </v-list-item>
                <v-list-item @click="selectItem('Submenu 2 - Item B')">
                  <v-list-item-title>Submenu 2 - Item B</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
        </v-menu>
      </v-container>
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menu1: true,         // Controls main menu
        submenu1: false,      // Controls first nested submenu
        submenu2: false       // Controls second nested submenu
      };
    },
    methods: {
      selectItem(item) {
        console.log("Selected:", item);
        // You can add further handling here, such as updating data or navigating
      }
    }
  };
  </script>
  
  <style scoped>
  /* Optional custom styles */
  </style>
  