<template>
  <div class="home-view">
    <Header />

    <main class="main-content">
      <section class="intro-section">
        <div class="text-content">
          <h1 class="heading">Make AI</h1>
          <p class="description">
            Make AI is an advanced chatbot designed to enable seamless cross-industry interactions
            and quick access to information. With an intuitive interface and context-specific responses,
            it simplifies complex data, supporting informed decision-making across sectors. Make AI provides
            immediate insights to optimize processes, drive innovation, and improve operational efficiency.
          </p>
          <button class="quick-assist" @click="redirect">QuickAssist</button>

          <h2 class="text-left mt-10 cat">Please select the category below:</h2>
          <ProductFilters class="h-full" @reRenderQuestion="reRenderQuestion" />
        </div>
        <div class="image-content">
          <img :src="require('@/assets/img/make_ai_4.png')" alt="Image" class="w-full h-screen object-cover" />
        </div>
      </section>
      <section v-if="false" class="categories">
        <h2 class="text-left w-1/2 cat">Please select the category below:</h2>
        <ProductFilters class="h-full" />

        <div class="cards" v-if="false">
          <div class="card">
            <div class="icon">📊</div>
            <h3>Deep Dive by Industry</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div class="card">
            <div class="icon">📈</div>
            <h3>Multi-Industry Efficiency</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
  import NestedMenu from '@/components/NestedMenu.vue';
  import ProductFilters from '@/components/ProductFilters/index.vue';
  import Header from '@/components/Header.vue';
  import { useRouter } from "vue-router";

  export default {
    name: 'HomeView',
    components: {
      NestedMenu,
      ProductFilters,
      Header
    },
    setup() {
      const router = useRouter();

      // Return router so it can be used in methods
      return { router };
    },
    methods: {
      redirect() {
        console.log("redirectredirect");
        this.router.push({ name: "chatbot" });
      },
      reRenderQuestion(dimension = "", category = "") {
        this.router.push({ name: 'chatbot', params: { dimension: dimension, category: category } });
      }
    }
  };
</script>
<style scoped>
  .cat {
    color: var(--Icons-Icons-Default-Icon, #FFF);
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
    /* 103.448% */
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 112.5% */
  }

  .home-view {
    /* background-image: url("@/assets/img/make_ai.png"); */
    background-color: #1C191C;
    color: #fff;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }

  .nav button {
    background: none;
    color: #fff;
    border: none;
    margin-right: 15px;
    cursor: pointer;
  }

  .profile {
    display: flex;
    align-items: center;
  }

  .profile img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 20px;
    min-height: 100vh;
    /* Ensures it takes full viewport height */
  }

  .intro-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-content {
    flex: 1;
    max-width: 60%;
    text-align: left;
    /* Adjust this value as needed */
  }

  .text-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }

  .text-content .description {
    margin-bottom: 20px;
    text-align: left;
  }

  .quick-assist {
    color: #fff;
    border: none;
    /* padding: 10px 20px; */
    cursor: pointer;
    border-radius: 10px;
    background: #A0186A;
    padding: 8px 24px;
  }

  .image-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .image-content img {
    max-width: 100%;
    height: auto;
  }

  .categories {
    /* margin-top: 40px; */
  }

  .cards {
    display: flex;
    gap: 20px;
  }

  .card {
    background-color: #2c2c3e;
    padding: 20px;
    border-radius: 10px;
    flex: 1;
  }

  .card h3 {
    margin: 15px 0;
  }

  .card .icon {
    font-size: 2em;
  }
</style>