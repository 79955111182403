import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import ChatbotView from "../views/ChatbotView.vue";
import NestedView from "../views/NestedView.vue";
// import ActionsView from "../views/ActionsView.vue";
// import HistoryView from "../views/HistoryView.vue";
// import ChatbotHistoryView from "../views/ChatbotHistoryView.vue";
// import SignalsView from '../views/SignalsView.vue'
// import SignalsViewAluminum from '../views/SignalsViewAluminum.vue'
// import StrategyView from '../views/StrategyView.vue'
// import StrategyViewAluminum from '../views/StrategyViewAluminum.vue'

const routes = [
  {
    path: "/",
    name: "",
    component: LoginView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    }
  },
//   {
//     path: "/actions/:mode",
//     name: "actions",
//     component: ActionsView,
//     meta: {
//       requiresAuth: true,
//     }
//   },
  {
    path: "/chatbot/:dimension?/:category?",
    name: "chatbot",
    component: ChatbotView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/menu",
    // /:category/:mode
    name: "menu",
    component: NestedView,
    meta: {
      // requiresAuth: true,
    }
  },
//   {
//     path: "/signals/ocean",
//     name: "signals",
//     component: SignalsView,
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: "/signals/aluminum",
//     name: "signalsaluminum",
//     component: SignalsViewAluminum,
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: "/strategy/ocean",
//     name: "strategy",
//     component: StrategyView,
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: "/strategy/aluminum",
//     name: "strategyaluminum",
//     component: StrategyViewAluminum,
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: "/chatHistory/:mode",
//     name: "chathistory",
//     component: ChatbotHistoryView,
//     props: true,
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: "/history/:mode",
//     name: "history",
//     component: HistoryView,
//   },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    const customerID = JSON.parse(localStorage.getItem('userData')).userId;
    
    if (!customerID) {
      // If CustomerID is not found in local storage, redirect to login page
      next({ name: "login" });
      alert("Please Sign in to access this page.");
    } else {
      // Continue with the navigation
      next();
    }
  } else {
    // For routes that don't require authentication, proceed with the navigation
    next();
  }
});

export default router;
