<template>
    <header class="header">
        <div class="logo">KEARNEY</div>
        <div v-if="isAuthenticated" class="flex justify-end items-center w-1/3">
        <nav class="nav">

            <button @click="redirectHome">
                <img :src="require('@/assets/img/Home.svg')" alt="" class="h-4 inline mb-1">
                Home</button>
                <button>
                <img :src="require('@/assets/img/help.svg')" alt="" class="h-4 inline">
                Help</button>
        </nav>
                
            <img :src="require('@/assets/img/user-image-logo.svg')" alt="" class="h-10 inline">
            <span>{{getUserData.firstName}}&nbsp;{{getUserData.lastName}}</span>

            <button class="relative">
                <img @click="toggleDropdown" :src="require('@/assets/img/arrow-down.svg')" alt="" class="">
            </button>
            <div v-show="dropdownOpen" class="fixed top-14 right-4 bg-white border rounded shadow-lg w-32 mt-2 z-10">
                <ul>
                    <li @click="logout" class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black">Logout</li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>
  import { useRouter } from "vue-router";

    export default {
        data() {
            return {
                dropdownOpen: false,
                isAuthenticated: !!localStorage.getItem('accessToken'), // Initial check for token
            }
        },
        
        name: 'Header',
        computed: {
            getUserData() {
                console.log(typeof JSON.parse(localStorage.getItem('userData')), "localStora");
                return JSON.parse(localStorage.getItem('userData'));
            },
        },
        methods: {
            toggleDropdown() {
                this.dropdownOpen = !this.dropdownOpen;
            },
            logout() {
                localStorage.clear(); // Clear local storage
                this.isAuthenticated = false; // Update reactive property
                this.$router.push({ name: "login" }); // Redirect to home page
                // window.location.reload();
            },
            redirectHome() {
                this.$router.push({name: "home"});
            },
            redirect() {
                this.$router.push({ name: "chatbot" });
            },

        }
    };
</script>
<style>
    .heading {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        /* 112.5% */
    }

    .home-view {
        background-color: #1C191C;
        color: #fff;
        padding: 20px;
        font-family: 'Arial', sans-serif;
        height: 100%;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        font-size: 1.5em;
        font-weight: bold;
    }

    .nav button {
        background: none;
        color: #fff;
        border: none;
        margin-right: 15px;
        cursor: pointer;
    }

    .profile {
        display: flex;
        align-items: center;
    }

    .profile img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .main-content {
        margin-top: 40px;
        margin-left: 20px;
    }

    .intro-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text-content {
        flex: 1;
        max-width: 60%;
        text-align: left;
        /* Adjust this value as needed */
    }

    .text-content h1 {
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .text-content .description {
        margin-bottom: 20px;
        text-align: left;
    }

    .quick-assist {
        color: #fff;
        border: none;
        /* padding: 10px 20px; */
        cursor: pointer;
        border-radius: 10px;
        background: #A0186A;
        padding: 8px 24px;
    }

    .image-content {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .image-content img {
        max-width: 100%;
        height: auto;
    }
</style>