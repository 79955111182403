<template>
	<div class="home-view">
		<Header />
		<ProductFilters @reRenderQuestion="fetchQuestion" :selectedDimension="selectedDimension"
			:selectedCategory="selectedCategory" />
		<Chatbot :quickQuestion="quickQuestion" />
		<!-- <main class="main-content">
      </main> -->
	</div>
</template>
<script>
	import ProductFilters from '@/components/ProductFilters/index.vue';
	import Chatbot from '@/components/Chatbot/index.vue'
	import Header from '@/components/Header.vue'
	export default {
		name: 'ChatbotView',
		components: {
			ProductFilters,
			Chatbot,
			Header
		},
		data() {
			return {
				dimension: "",
				category: "",
				selectedDimension: this.$route.params.dimension,
				selectedCategory: this.$route.params.category,
				quickQuestion: [
					// "What is Tawal’s market share ?",
					// "What are the unit economics of telecom company ?",
					// "Help us with business model for the media subsidiary of a telecom company.",
					// "What is CO2 stripping process ?"
				]
			}
		},
		mounted() {
			this.fetchQuestion();
		},
		methods: {
			redirect() {
				router.push({ name: "chatbot" });
			},
			async fetchQuestion(a = "Energy efficiency", d = "Cross industry") {
				this.dimension = d;
				this.category = a;

				try {
					const userResponse = await fetch(
						`${process.env.VUE_APP_API_BASE_URL}/file_upload/quick_questions?dimensions=${encodeURIComponent(this.dimension)}&category=${encodeURIComponent(this.category)}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
							}
						}
					);

					// Check if response is OK (status in the range 200-299)
					if (!userResponse.ok) {
						throw new Error(`HTTP error! Status: ${userResponse.status}`);
					}

					// Read the response data as JSON
					const data = await userResponse.json();
					console.log('Response Data:', data.quickQuestion);
					this.quickQuestion = data.quickQuestion;
					// Use `data` as needed in your component

				} catch (error) {
					console.error("Error fetching question:", error);
					// Optionally, handle error state in the component
				}
			}
		}
	};
</script>

<style scoped>
	.heading {
		font-size: 64px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
		/* 112.5% */
	}

	.home-view {
		background-color: #1C191C;
		color: #fff;
		padding: 20px;
		font-family: 'Arial', sans-serif;
		height: 100vh; /* Set to full viewport height */
		display: flex;
		flex-direction: column;
		/* overflow: hidden; */
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		font-size: 1.5em;
		font-weight: bold;
	}

	.nav button {
		background: none;
		color: #fff;
		border: none;
		margin-right: 15px;
		cursor: pointer;
	}

	.profile {
		display: flex;
		align-items: center;
	}

	.profile img {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	/* .main-content {
		margin-top: 40px;
		margin-left: 20px;
	} */

	.main-content {
		flex: 1; /* Allows main content to take up remaining space */
		overflow-y: auto; /* Adds scroll within content if it exceeds available space */
		margin-top: 40px;
		margin-left: 20px;
	}

	.intro-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1; /* Grow to take available space */
	}

	.text-content {
		flex: 1;
		max-width: 60%;
		text-align: left;
		/* Adjust this value as needed */
	}

	.text-content h1 {
		font-size: 2.5em;
		margin-bottom: 10px;
	}

	.text-content .description {
		margin-bottom: 20px;
		text-align: left;
	}

	.quick-assist {
		color: #fff;
		border: none;
		/* padding: 10px 20px; */
		cursor: pointer;
		border-radius: 10px;
		background: #A0186A;
		padding: 8px 24px;
	}

	.image-content {
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}

	.image-content img {
		max-width: 100%;
		height: auto;
	}

	.categories {
		margin-top: 40px;
	}

	.cards {
		display: flex;
		gap: 20px;
	}

	.card {
		background-color: #2c2c3e;
		padding: 20px;
		border-radius: 10px;
		flex: 1;
	}

	.card h3 {
		margin: 15px 0;
	}

	.card .icon {
		font-size: 2em;
	}
</style>