<script setup>
  const props = defineProps({
    categoryArr: Array,
    dimension: {
      type: String,
      default: 'dsss',
    },
  });
  const emits = defineEmits(['categorySelected'])

  const selectCategory = (category) => {
    emits('categorySelected', category, props.dimension)
  }
</script>

<template>
  <div class="category-list">
    <div v-for="(category, index) in categoryArr" :key="index" class="category-item" @click="selectCategory(category)">
      {{ category }}
    </div>
  </div>
</template>

<style scoped>
  .category-list {
    padding: 8px;
    max-height: calc(5 * 40px);
    /* Adjust height based on item height, e.g., 5 items each 40px tall */
    overflow-y: auto;
    /* Enable vertical scrolling when content exceeds max height */
  }

  .category-item {
    padding: 8px;
    cursor: pointer;
    color: #e0e0e0;
    background-color: #2B292B;
  }

  .category-item:hover {
    background-color: rgba(73, 16, 58, 0.44);
  }
</style>