<script setup>
  import { ref, onMounted } from 'vue';
  import ElementsCategory from './ElementsCategory/index.vue'
  const props = defineProps({
    selectedDimension: {
      type: String,
      default: '',
    },
    selectedCategory: {
      type: String,
      default: '',
    },
  });
  // Data structure with two dimensions, each having nested categories.
  const tempFilterData = [
    {
      Dimension: 'Industry specific',
      Categories: [
        'Ammonia, Acids and Fertilizers',
        'Oil and gas refining',
        'Refinery energy systems and efficiency',
        'Glass manufacturing',
        'Polymers',
        'Specialty inorganics',
        'Large volume inorganics',
        'Organic chemicals',
        'Storage',
        'Non ferrous metals',
        'Chlor Alkali',
        'Ceramic manufacturing',
        'Cement Lime MgO',
        'Pulp and paper',
        'Poultry and pigs rearing',
        'Smitheries and Foundry',
        'Surface treatment of metals and plastics',
        'Tanning of hides',
        'Textiles',
      ],
    },
    {
      Dimension: 'Cross industry',
      Categories: [
        'Energy efficiency',
        'Industrial cooling systems',
        'Emissions to air',
        'Waste water and waste gas',
        'Steam pressure reduction',
      ],
    },
  ]

  // Reactive state for controlling dropdown behavior
  const showDimensions = ref(false)
  const showCategories = ref(false)
  const selectedCategory = ref(props.selectedCategory)
  const hoveredDimension = ref(null)
  const selectedDimension = ref(null)
  const emits = defineEmits(['reRenderQuestion'])

   // Emit reRenderQuestion on component mount
   onMounted(() => {
    // emits('reRenderQuestion', props.selectedDimension, props.selectedCategory);
  });

  // Toggle function for displaying the category list
  const toggleCategoryList = (state, dimension) => {
    showCategories.value = state
    hoveredDimension.value = dimension;

  }

  // Function to handle category selection
  const selectCategory = (category, dimension) => {
    console.log(category, 'category');
    selectedCategory.value = category;
    showDimensions.value = false
    showCategories.value = false
    selectedDimension.value = dimension;
    console.log("emitemit");
    emits('reRenderQuestion', dimension, category);
  }
</script>

<template>
  <div class="select-filter">
    <!-- Dropdown trigger input -->
    <div class="choose-category">
      <div class="dropdown" @click="showDimensions = !showDimensions">
        <input type="text" class="dropdown-input" :placeholder="selectedCategory || 'Select Category'" readonly />
        <img :src="require('@/assets/img/CaretDown.svg')" alt="CaretDown-icon" class="h-10 mr-2 caret-icon" />
      </div>

      <!-- First level: Dimension list -->
      <div v-if="showDimensions" class="dropdown-menu">
        <div v-for="(dimension, index) in tempFilterData" :key="index" class="dropdown-item"
          @mouseenter="toggleCategoryList(true, dimension.Dimension)" @mouseleave="toggleCategoryList(false, null)">
          {{ dimension.Dimension }}

          <!-- Second level: Category list for hovered dimension -->
          <div v-if="showCategories && hoveredDimension === dimension.Dimension" class="nested-dropdown">
            <ElementsCategory :dimension="dimension.Dimension" :category-arr="dimension.Categories"
              @categorySelected="selectCategory" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .select-filter {
    position: relative;
  }

  .choose-category .dropdown {
    position: relative;
    width: 310px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;
    /* Adjust width as needed */
    padding-right: 10px;
    /* border: 1px solid rgba(160, 24, 106, 0.52); */
    /* Space to the right for the icon */
  }

  .dropdown-input {
    flex: 1;
    /* Makes the input take available space */
    padding-right: 35px;
    /* Space to the right for the icon */
    cursor: pointer;
    width: 300px;
    padding: 8px;
    background-color: #2B292B;
    color: #e0e0e0;
    border: 1px solid #444;
  }

  .caret-icon {
    position: absolute;
    right: 10px;
    /* Position on the right side */
    height: 24px;
    /* Adjust height as needed */
    pointer-events: none;
    /* Icon won't interfere with click */
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    background-color: #2B292B;
    border: 1px solid #444;
    z-index: 10;
  }

  .dropdown-item {
    padding: 8px;
    color: #e0e0e0;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: rgba(73, 16, 58, 0.44);
  }

  .nested-dropdown {
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    background-color: #2B292B;
    border: 1px solid #444;
    z-index: 20;
  }
</style>